
      .drag-area {
        border: 2px dashed #555;
        height: 500px;
        width: 700px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    
      @media screen and (max-width: 768px) {
        .drag-area {
          height: 300px;
          width: 90%;
        }
      }
    
      .drag-area.active {
        border: 2px solid #555;
      }
    
      .drag-area .icon {
        font-size: 100px;
        color: #555;
      }
    
      .drag-area header {
        font-size: 30px;
        font-weight: 500;
        color: #555;
      }
    
      .drag-area span {
        font-size: 25px;
        font-weight: 500;
        color: #555;
        margin: 10px 0 15px 0;
      }
    
      .drag-area button {
        padding: 10px 25px;
        font-size: 25px;
        font-weight: 500;
        border: none;
        outline: none;
        background-color: #555;
        color: #eee;
        border-radius: 5px;
        cursor: pointer;
      }
    